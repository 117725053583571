import axios from '../axios';
import { GROUP_BE_URL } from '../helpers/api_helper';
import {
  SEND_SUCCESS_MESSAGE,
  GET_ERRORS,
  GET_ALL_GROUP,
  GET_GROUP_BY_ID,
  SET_GROUP_TO_OPTION,
  UPDATE_GROUP_CUSTOMER,
  DELETE_GROUP,
  REMOVE_ALL_GROUP,
  REMOVE_GROUP,
  REMOVE_GROUP_TO_OPTION,
  SET_GROUP_OPTIONS_FETCHING,
} from './actionTypes';

import { hasString, hasArray } from '../helpers/data_helper';
import { errorHelper } from '../helpers/error_helper';

// get
export const getAllGroup = (
  page,
  pageSize,
  searchTerm = '',
  sortField = '_id',
  sortDir = 1
) => dispatch => {
  dispatch({ type: REMOVE_ALL_GROUP });

  axios
    .get(GROUP_BE_URL, {
      params: { page, pageSize, searchTerm, sortField, sortDir },
    })
    .then(res => {
      let {
        data: { groups, total_rows },
      } = res.data;

      groups = groups.map(g => {
        let customers = hasArray(g.customers);

        let company_code = '';

        customers = customers.map(c => {
          company_code = hasString(c.company_code);

          return {
            _id: hasString(c._id),
            code: hasString(c.code),
            p_iva: hasString(c.p_iva),
            business_name: hasString(c.business_name),
            email: hasString(c.email),
            company_code: hasString(c.company_code),
            location_code: hasArray(c.location_code),
          };
        });

        return {
          _id: hasString(g._id),
          group_code: hasString(g.group_code),
          group_name: hasString(g.group_name),
          customers,
          company_code,
        };
      });

      dispatch({ type: GET_ALL_GROUP, payload: { groups, total_rows } });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const setGroupToOption = () => (dispatch, getState) => {
  const store = getState();
  const groupToOption = store?.app?.group?.groupToOption;

  if (groupToOption?.loaded || groupToOption?.loading) return;

  dispatch({ type: SET_GROUP_OPTIONS_FETCHING });

  axios
    .get(GROUP_BE_URL)
    .then(res => {
      let {
        data: { groups },
      } = res.data;

      groups = groups.map(g => {
        let customers = hasArray(g.customers);
        customers = customers.map(c => {
          return {
            _id: hasString(c._id),
            code: hasString(c.code),
            p_iva: hasString(c.p_iva),
            business_name: hasString(c.business_name),
            email: hasString(c.email),
            company_code: hasString(c.company_code),
            location_code: hasArray(c.location_code),
          };
        });

        return {
          _id: hasString(g._id),
          group_code: hasString(g.group_code),
          group_name: hasString(g.group_name),
          customers,
        };
      });

      dispatch({ type: SET_GROUP_TO_OPTION, payload: groups });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const getGroupById = _id => dispatch => {
  axios
    .get(`${GROUP_BE_URL}/${_id}`)
    .then(res => {
      let {
        data: { group },
      } = res.data;

      let customers = hasArray(group.customers);
      let company_code = '';

      customers = customers.map(c => {
        company_code = hasString(c.company_code);

        return {
          _id: hasString(c._id),
          code: hasString(c.code),
          p_iva: hasString(c.p_iva),
          business_name: hasString(c.business_name),
          email: hasString(c.email),
          company_code: hasString(c.company_code),
          location_code: hasArray(c.location_code),
        };
      });
      const client_code = customers.map(c => {
        return c.code;
      });

      group = {
        _id: hasString(group._id),
        group_code: hasString(group.group_code),
        group_name: hasString(group.group_name),
        customers,
        client_code,
        clients: customers,
        company_code,
      };

      dispatch({ type: GET_GROUP_BY_ID, payload: group });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

// post
export const createGroup = (data, history) => dispatch => {
  const { group_name, customers } = data;
  const group = { group_name, customers };

  axios
    .post(GROUP_BE_URL, group)
    .then(res => {
      const { message } = res.data;

      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: message });

      history.push('/group/list');
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

// put
export const updateGroup = (_id, data, history) => dispatch => {
  const { group_code, group_name, customers } = data;
  const group = { group_code, group_name, customers };

  axios
    .post(`${GROUP_BE_URL}/${_id}/update`, group)
    .then(res => {
      const { message } = res.data;

      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: message });

      history.push('/group/list');
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

export const updateCustomers = (_id, data) => dispatch => {
  const { group_code, group_name, customers } = data;
  const group = { group_code, group_name, customers };

  axios
    .post(`${GROUP_BE_URL}/${_id}/update`, group)
    .then(res => {
      dispatch({ type: UPDATE_GROUP_CUSTOMER, payload: { _id, customers } });
      const { message } = res.data;

      dispatch({ type: SEND_SUCCESS_MESSAGE, payload: message });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

// delete
export const deleteGroup = _id => dispatch => {
  axios
    .get(`${GROUP_BE_URL}/${_id}/delete`)
    .then(res => {
      dispatch({ type: DELETE_GROUP, payload: _id });

      dispatch({
        type: SEND_SUCCESS_MESSAGE,
        payload: 'Eliminato con successo',
      });
    })
    .catch(err => {
      const errors = errorHelper(err);

      dispatch({ type: GET_ERRORS, payload: errors });
    });
};

// remove
export const removeAllGroup = () => dispatch => {
  dispatch({ type: REMOVE_ALL_GROUP });
};

export const removeGroup = () => dispatch => {
  dispatch({ type: REMOVE_GROUP });
};

export const removeGroupToOption = () => dispatch => {
  dispatch({ type: REMOVE_GROUP_TO_OPTION });
};
